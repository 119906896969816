import React from "react";
import styled, { keyframes } from "styled-components";
// import Footer from "./Components/Footer";
import backgroundImage from "../images/Planet_Background.png";

// Keyframe animation for card appearance
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Wrapper to center the card
const CenterWrapper = styled.div`
  display: flex;
  background-image: url(${backgroundImage});
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
`;

// Main container for the card with entry animation and responsive design
const CardContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 70% opacity */
  color: #ffffff; /* White text color */
  border-radius: 15px;
  width: 500px; /* Default width */
  max-width: 90%; /* Responsive width */
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Slight shadow effect */
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease-in-out; /* Animation for card entry */

  /* Hover effect */
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
  }

  /* Responsive design */
  @media (max-width: 768px) {
    width: 300px; /* Adjust width for tablet */
  }

  @media (max-width: 480px) {
    width: 250px; /* Adjust width for phone */
    padding: 20px; /* Reduce padding */
  }
`;

// Title styling with additional margin and responsive font size
const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: white; /* Blue color */
  margin: 0;
  margin-bottom: 25px;
  text-align: center; /* Center the title text */

  @media (max-width: 768px) {
    font-size: 1.8rem; /* Slightly smaller for tablet */
  }

  @media (max-width: 480px) {
    font-size: 1.5rem; /* Smaller for phone */
  }
`;

// Text container with responsive margin and spacing
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 1.6;
  margin-bottom: 20px; /* Add margin for spacing */

  @media (max-width: 480px) {
    gap: 8px; /* Reduce gap for phone */
    margin-bottom: 15px;
  }
`;

// Text styling with adjusted colors and spacing
const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #e0e0e0; /* Slightly lighter grey for readability */
  text-align: center; /* Center align text for a balanced look */

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Smaller text for phone */
  }
`;

// Contact details container with spacing and layout adjustments
const ContactDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Space out contact details vertically */
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content */
  text-align: center;
`;

const ContactLabel = styled.span`
  font-weight: bold;
  font-size: 1.1rem;

  @media (max-width: 480px) {
    font-size: 1rem; /* Adjust font size for phone */
  }
`;

const ContactText = styled.span`
  font-size: 0.9rem;
  color: #cccccc;

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Smaller text for phone */
  }
`;

const Contact = () => {
  return (
    <>
      <CenterWrapper>
        <CardContainer>
          <Title>REGIME MUSIC SOCIETE</Title>
          <TextContainer>
            <Text>3424 Peachtree Road NE Suite 2200</Text>
            <Text>Atlanta, GA 30326</Text>
          </TextContainer>
          <ContactDetails>
            <ContactInfo>
              <ContactLabel>Phone</ContactLabel>
              <ContactText>(404) 736 4228</ContactText>
            </ContactInfo>
            <ContactInfo>
              <ContactLabel>Email</ContactLabel>
              <ContactText>efe@rmsociete.com</ContactText>
            </ContactInfo>
          </ContactDetails>
        </CardContainer>
      </CenterWrapper>
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
