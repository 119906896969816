import React from "react";
import styled from "styled-components";
// import Footer from "./Components/Footer";
// import { FaPause } from "react-icons/fa"; // Importing an icon from react-icons

// Styled components for the layout
const Wrapper = styled.section`
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(135deg, #1f1c2c, #b1b1b1);
  min-height: 100vh;
  color: white;
`;

const Header = styled.h1`
  margin-top: 60px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #f8f9fa;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const SubHeader = styled.p`
  font-size: 1.2rem;
  color: #e0e0e0;
  max-width: 800px;
  margin: 20px auto;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);

    img {
      transform: scale(1.1);
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: left;
  transition: background 0.3s ease;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  color: #ffffffcc;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  transition: color 0.3s ease;

  ${GridItem}:hover & {
    color: #ffffff;
  }
`;

// Types for props
interface GridItemProps {
  title: string;
  imageUrl: string;
}

// Component for each grid item
const ImageCard: React.FC<GridItemProps> = ({ title, imageUrl }) => {
  return (
    <GridItem>
      <img src={imageUrl} alt={title} />
      <Overlay>{title}</Overlay>
      <Icon>{/* <FaPause /> */}</Icon>
    </GridItem>
  );
};

// Main Component
const Music: React.FC = () => {
  const categories = [
    {
      title: "UNDERINFLUENCE",
      imageUrl: require("../images/underTheInfluence.jpeg"),
    },
    { title: "PERU", imageUrl: require("../images/Peru.png") },
    {
      title: "PERU REMIX",
      imageUrl: require("../images/peru_remix.jpg"),
    },
    {
      title: "PERU REMIX R3HAB",
      imageUrl: require("../images/peruRemixR3hab.jpg"),
    },
    {
      title: "FALL",
      imageUrl: require("../images/Fall.jpg"),
    },
    {
      title: "BOYS AIN'T SHIT",
      imageUrl: require("../images/boysAintShit.jpg"),
    },
    {
      title: "BLOW MY MIND",
      imageUrl: require("../images/BlowMyMind.png"),
    },
    {
      title: "ISKABA",
      imageUrl: require("../images/iskaba.jpg"),
    },
    {
      title: "FELA IN VERSACE",
      imageUrl: require("../images/fela_versace.png"),
    },
    {
      title: "SKELEWU",
      imageUrl: require("../images/SkelewuCoverArt.jpg"),
    },
  ];

  return (
    <>
      <Wrapper>
        <Header>Our Joint Publishing Track</Header>
        <SubHeader>
          RMS artists are trendsetters, global hit makers, and culture creators
          interacting with billions of fans. Partner with us to supercharge your
          content and be discovered by new customers every day.
        </SubHeader>
        <Grid>
          {categories.map((category, index) => (
            <ImageCard
              key={index}
              title={category.title}
              imageUrl={category.imageUrl}
            />
          ))}
        </Grid>
      </Wrapper>
      {/* <Footer /> */}
    </>
  );
};

export default Music;
