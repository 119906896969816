import { Outlet } from "react-router-dom";
import Header from "./Header";

function Layout() {
  return (
    <>
      <Header /> {/* Always render the Header */}
      <Outlet /> {/* Render the child route component */}
    </>
  );
}

export default Layout;
