import React from "react";
import styled from "styled-components";
import imageUrl_OC from "../images/Original_Compositions_RMS.png";
import imageUrl_DI from "../images/Distribution.png";
import imageUrl_CL from "../images/Transaction_Illustration.png";
import imageUrl_DV from "../images/Genre_Diversity.png";
import Rms_logo from "../images/RMS_logo.png";
// import Footer from "./Components/Footer";

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 1200px;
  margin: auto;
`;

const AboutTextContainer = styled.div`
  flex: 1;
  max-width: 50%;
  margin-top: 100px;
  justify-content: center;
  padding: 40px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 20px;
  }
`;

const Heading = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  color: #a89250;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const LearnMoreButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    background-color: #0066ff;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 102, 255, 0.4);
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 1200px;
  margin: auto;
`;

// Container for each section (text and image)
const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

// Text content container
const TextContainer = styled.div`
  flex: 1;
  margin: 20px;
  max-width: 600px;

  h2 {
    font-size: 2.5rem;

    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  span {
    color: #a89250; /* Custom color for highlighted text */
    font-weight: bold;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

// Image container styling
const ImageContainer = styled.div`
  flex: 1;
  max-width: 500px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 20px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

// Image container styling
const LogoImageContainer = styled.div`
  max-width: 400px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 300px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const About = () => {
  return (
    <>
      <AboutSection>
        <AboutTextContainer>
          <LogoImageContainer>
            <img src={Rms_logo} alt="RMS_logo" />
          </LogoImageContainer>
          <Heading>REGIME MUSIC COCIETE</Heading>
          <Description>
            Regime Music Societe is Global Artist services, and music publishing
            corporation founded in Atlanta, Georgia. Our quality, creative and
            professional services contribute to music that is distributed and
            enjoyed globally.
          </Description>
          <LearnMoreButton>Learn More</LearnMoreButton>
        </AboutTextContainer>
      </AboutSection>
      <MainContainer>
        <SectionContainer>
          <TextContainer>
            <h2>Original Compositions</h2>
            <p>
              RMS conceptualizes, creates and collects the revenue for original
              compositions that are produced in-house and distributed through
              various distributors and publisher across the world. RMS is also a
              consultate for{" "}
              <span>
                FIFA Sound on the curating and producing an original composition
                for the official anthem for the FIFA 2024 U20 Women's World Cup
                in Columbia.
              </span>{" "}
            </p>
          </TextContainer>
          <ImageContainer>
            <img
              src={imageUrl_OC}
              alt="Original Composition Services Description of RMS"
            />
          </ImageContainer>
        </SectionContainer>
        <SectionContainer>
          <ImageContainer>
            <img src={imageUrl_DV} alt="Music Distribution Services" />
          </ImageContainer>
          <TextContainer>
            <h2>Genre Diversity</h2>
            <p>
              The RMS catalog actively promotes diverse musical genres, such as
              Afro Beats, Reggaton, American Pop. RMS compositions have appealed
              to a wide range of audiences worldwide with{" "}
              <span>
                recording agreements with Def Jam, Epic and Interscope Records.
                Publishing agreements with Universal Music Publishing and
                Sony/ATV Music Publishing.
              </span>
              Notable accomplishments include securing the first two major U.S.
              publishing agreements for Afrobeats pioneering artists.
            </p>
          </TextContainer>
        </SectionContainer>
        <SectionContainer>
          <TextContainer>
            <h2>Collaborations</h2>
            <p>
              <span>
                RMS has collaborated closely with artists, musicians, and
                composers from various backgrounds, and released and collected
                on musical offerings that have resonated with fans across
                cultures.
              </span>
              These musical collaborations have brought cultures together under
              a song while also being beneficial for the artist and profitable
              for RMS and its partners. RMS has participated in the creation of
              several iconic collaborative anthems for the FIFA World Cup,
              including the U20 Women's Wold Cup in Colombia. These tracks are
              linked to FIFA World Cups and other tournaments, serving as
              official songs, or branded Sonic Identities.
            </p>
          </TextContainer>
          <ImageContainer>
            <img
              src={imageUrl_CL}
              alt="Original Composition Services Description of RMS"
            />
          </ImageContainer>
        </SectionContainer>
        <SectionContainer>
          <ImageContainer>
            <img src={imageUrl_DI} alt="Music Distribution Services" />
          </ImageContainer>
          <TextContainer>
            <h2>Distribution</h2>
            <p>
              Regime has the experience, expertise and intelligence to navigate
              FIFA™ through the various administrative, managerial, production,
              and recording entities and personnel required to deliver the
              results needed for FIFA™.{" "}
              <span>
                Over the years, RMS has partnered with numerous labels,
                distributors and publishers to release music, including Def Jam,
                Interscope, Universal Music Group, SonyATV Music Publishing, to
                name a few.
              </span>
            </p>
          </TextContainer>
        </SectionContainer>
      </MainContainer>
      {/* <Footer /> */}
    </>
  );
};

export default About;
