import styled from "styled-components";
// import Footer from "./Components/Footer";
import PartnerSectionComponent from "./Components/partners";
import backgroundImage from "../images/Abstract_WP.png";

// Main container with background image and overlay
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  position: relative;
  color: #f5f5e5;
  text-align: center;
  font-family: "Serif", serif;

  /* Dark overlay to enhance text readability */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity as needed */
    z-index: 1; /* Place overlay behind content */
  }
`;

// Adjusted styling to position text content above the overlay
const Name = styled.h1`
  font-size: 5rem;
  font-weight: 900;
  letter-spacing: 0.1rem;
  line-height: 1.2;
  z-index: 2; /* Ensures content is above overlay */

  span {
    display: block;
  }

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-top: 20px;
  max-width: 600px;
  line-height: 1.6;
  letter-spacing: 0.05rem;
  z-index: 2; /* Ensures content is above overlay */

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const ScrollDown = styled.div`
  margin-top: 40px;
  animation: bounce 2s infinite;
  z-index: 2; /* Ensures content is above overlay */

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }
`;

const Arrow = styled.div`
  font-size: 2rem;
  color: #f5f5e5;
`;

// Home component
function Home() {
  return (
    <>
      <Container>
        <Name>
          <span>REGIME MUSIC</span> <br />
          <span>SOCIETE</span>
        </Name>
        <Subtitle>THE ULTIMATE DIGITAL DISCOTHEQUE</Subtitle>
        <ScrollDown>
          <Arrow>↓</Arrow>
        </ScrollDown>
      </Container>
      <PartnerSectionComponent />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
