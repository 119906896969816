import { createBrowserRouter } from "react-router-dom";
import Home from "./Routes/Home";
import About from "./Routes/About";
import Layout from "./Routes/Components/Layout"; // Import the Layout component
import Music from "./Routes/Music";
import Contact from "./Routes/Contact";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />, // Wrap everything in Layout
    children: [
      {
        path: "/",
        element: <Home />, // Home will be rendered inside Layout
      },
      {
        path: "about",
        element: <About />, // About will be rendered inside Layout
      },
      {
        path: "music",
        element: <Music />, // About will be rendered inside Layout
      },
      {
        path: "contact",
        element: <Contact />, // About will be rendered inside Layout
      },
    ],
  },
]);
