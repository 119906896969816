import React from "react";
import styled from "styled-components";
import {
  FaSpotify,
  FaApple,
  FaTiktok,
  FaInstagram,
  FaYoutube,
  FaAmazon,
} from "react-icons/fa"; // Import required icons
// import SonyLogo from "../../images/Sony_logo.png";

// Styled Components for the Section
const PartnerSection = styled.section`
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9;
`;

const Heading = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;

  span {
    color: #b38b3c; /* Highlight color */
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const PartnerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px; /* Space between icons */
  margin: 30px 10px;

  @media (max-width: 768px) {
    gap: 20px; /* Reduce gap for tablets */
  }

  @media (max-width: 480px) {
    gap: 15px; /* Reduce gap for mobile devices */
  }
`;

const PartnerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #333;

  svg {
    font-size: 40px; /* Icon size */
    margin-bottom: 10px;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff; /* Change color on hover */
    }
  }

  @media (max-width: 768px) {
    font-size: 18px;

    svg {
      font-size: 35px;
    }
  }

  @media (max-width: 480px) {
    font-size: 16px;

    svg {
      font-size: 30px;
    }
  }
`;

const PartnerText = styled.span`
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const PartnerSectionComponent = () => {
  return (
    <PartnerSection>
      <Heading>
        We worked with <span>50+ Global Comapny</span>
      </Heading>
      <PartnerContainer>
        <PartnerItem>
          <FaSpotify />
          <PartnerText>Spotify</PartnerText>
        </PartnerItem>
        <PartnerItem>
          <FaApple />
          <PartnerText>Apple Music</PartnerText>
        </PartnerItem>
        <PartnerItem>
          <FaTiktok />
          <PartnerText>TikTok</PartnerText>
        </PartnerItem>
        <PartnerItem>
          <FaInstagram />
          <PartnerText>Instagram</PartnerText>
        </PartnerItem>
        <PartnerItem>
          <FaAmazon />
          <PartnerText>Amazon Music</PartnerText>
        </PartnerItem>
        <PartnerItem>
          <FaYoutube />
          <PartnerText>YouTube</PartnerText>
        </PartnerItem>
      </PartnerContainer>
    </PartnerSection>
  );
};

export default PartnerSectionComponent;
